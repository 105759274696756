.form-header {
  border: 1px solid #979797;
  padding: 10px;
  background: #e1e1e1;

  .test-heading {
    font-size: 25px;
    font-weight: 600;
    color: #42526e;
    text-decoration: underline;
  }
}

.top-headings {
  text-align: center;
}

.form-inner {
  padding: 10px;
  border: 1px solid #979797;

}

.MuiBox-root {
  padding: 13px 0 imo !important;

  ul {
    list-style-type: none;

    li {
      line-height: 30px;
    }

    input[type='checkbox'] {
      margin-right: 8px !important;
    }
  }
}

.head-line {
  line-height: 30px;
  margin-bottom: 10px;

  .btn {
    background: #0052cc !important;
    width: 148px;
  }

  hr {
    width: 98%;
  }
}

.site-identity h1 {
  font-size: 1.5em;
  display: inline-block;
}


.site-navigation ul,
.site-navigation li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.css-1x1xx5k {
  max-width: 100% !important;
}

.question-wrapper {
  div {
    button {
      display: none;
    }
  }
}

