.new {
    display: flex;
    width: 100%;
    .newContainer {
        flex: 4;
        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 20px 10px 20px;

            h3 {
                font-size: 25px;
                color: #42526e;
            }
        }

        .termand-condition-data{
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px 20px 10px 20px;

        h4{
            font-size: 22px;
            color: #42526e;
            margin-bottom: 10px;
        }
        h5{
            font-size: 20px;
            color: #42526e;
            font-weight: normal;
            margin: 10px 0px;
        }
        }

    }
}