.notification {
    display: flex;
    width: 100%;

    .newContainer {
        flex: 6;
    }

    .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        margin: 20px;
    }

    .listTitle {
        margin-bottom: 20px;
        font-size: 20px;
        color: #42526e;
        font-weight: bold;
    }
}