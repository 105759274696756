.signin {
    background-color: #dddddd;
    min-height: 100vh;
    position: relative;
    .sign-in-row {
        display: flex;
        flex-direction: row;
        border: 0;
        border-radius: 15px;
        box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
        overflow: hidden;
        background-color: #ffffff;
        height: 580px;
        width: 1000px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .signin-col-1 {
            flex-basis: 50%;
            img {
                border-radius: 0;
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                object-position: bottom;
            }
        }
        .signin-col-2 {
            flex-basis: 50%;
            padding: 60px 60px 60px 60px;
            h4 {
                font-size: 30px;
                color: #42526e;
                font-weight: normal;
                margin-bottom: 23px;
                margin-top: 0;
            }
            img {
                height: 37px;
                margin-bottom: 10px;
            }
            .forgot-password-link {
                font-size: 15px;
                margin-bottom: 12px;
                display: block;
                text-align: right;
                color: #42526e;
                cursor: pointer;

            }
            .forgot-password-link:hover{
                color: #2d2de8;
                text-decoration: underline;
            }
            form{
                .formInput{
                    margin-bottom: 15px;
                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                      }
                      input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                      }
                }
                .btn-submit {
                    width: 150px;
                    padding: 10px 20px;
                    display: block;
                    text-align: center;
                    border: none;
                    background-color: #0052cc;
                    color: white;
                    border-radius: 4px;
                    font-weight: bold;
                    cursor: pointer;
                    margin-top: 10px;
                    text-decoration: none;
                }
            }
        }

    }
}