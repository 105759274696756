.single {
    display: flex;
    width: 100%;

    .singleContainer {
        flex: 6;

        .top {
            padding: 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;


                .itemImg {
                    width: 100%;
                    height: unset;
                    border-radius: 8px;
                    object-fit: cover;
                }


            }
        }

        .right {
            flex: 2;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;

            .title {
                font-size: 26px;
                color: #172b4d;
                margin-bottom: 20px;
                border-bottom: 1px solid #cdcecf;
                padding-bottom: 15px;
            }

            .editButton {
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px 12px;
                font-size: 16px;
                background-color: #0052cc;
                cursor: pointer;
                border-radius: 0px 0px 0px 5px;
                font-weight: 500;
                text-decoration: none;
                border: 1px solid #0052cc;
                color:#fff
            }

            .details {
                .itemTitle {
                    margin-bottom: 10px;
                    color: #42526e;
                }

                .detailItem {
                    margin-bottom: 20px;
                    color: #42526e;

                    .itemKey {
                        font-weight: bold;
                        color: #42526e;
                        width: 200px;
                        display: inline-block;
                        font-size: 18px;
                    }

                    .itemKey2 {
                        color: #000000;
                        font-size: 16px;
                    }

                    .itemValue {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 10px 20px;
    }

    .title {
        font-size: 20px;
        color: #42526e;
        margin-bottom: 20px;
    }
}