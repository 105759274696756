
.resource {
    display: flex;
    width: 100%;

    .resourceContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            margin: 20px 0px 10px 0px;


            h3 {
                font-size: 25px;
                color: #42526e;
                font-weight: 700;
                margin-bottom:unset;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                border: 1px solid #0052cc;
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
                text-transform: capitalize;
                margin-left: 10px;
                svg{
                    font-size: 16px;
                }
            }
        }

        .view-details-row{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin:20px;
                .detailItem{
                    flex-basis: 100%;
                    margin-bottom: 20px;
                    .itemKey{
                        font-weight: 600;
                        width: 30%;
                        display: inline-block;
                    }
                }
        }
        .top {
            padding: 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;


                .itemImg {
                    width: 100%;
                    height: unset;
                    border-radius: 8px;
                    object-fit: cover;
                }


            }
        }

        

        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 30%;

                    p{
                        margin: unset;
                    }
                    
                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                    .select {
                        min-width: 100%;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }


            }

            .btn-submit {
                width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }

    }
}