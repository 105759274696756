.new {
    display: flex;
    width: 100%;

    .newContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 20px 10px 20px;

            h3 {
                font-size: 25px;
                color: #42526e;
            }


        }

        .loader-pickers-col {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .modal-btn {
                min-width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
            .toast{
                min-width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }

    }
}

.popup-confirming {
    text-align: center;

    h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 15px;
    }

    button.yes {
        min-width: 80px;
        padding: 10px 20px;
        text-align: center;
        border: none;
        background-color: #cc0000;
        color: white;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        text-decoration: none;
        margin-right: 10px;
    }

    button.no {
        min-width: 80px;
        padding: 10px 20px;
        text-align: center;
        border: none;
        background-color: #000000;
        color: white;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        text-decoration: none;
    }
}