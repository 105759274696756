.register {
    background-color: #dddddd;
    min-height: 105vh;
    position: relative;

    .sign-in-row {
        display: flex;
        flex-direction: row;
        border: 0;
        border-radius: 15px;
        box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
        overflow: hidden;
        background-color: #ffffff;
        height: 800px;
        width: 1000px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .signin-col-1 {
            flex-basis: 50%;
            background-color: #dddddd;
            position: relative;

            img {
                border-radius: 0;
                width: unset;
                height: unset;
                -o-object-fit: unset;
                object-fit: unset;
                object-position: unset;

            }

            .left-logo-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: unset;
                height: 95px;
            }
        }

        .signin-col-2 {
            flex-basis: 50%;
            padding: 55px 60px 60px 60px;

            h4 {
                font-size: 25px;
                color: #42526e;
                font-weight: normal;
                margin-bottom: 8px;
                margin-top: 0;
            }

            img {
                height: 37px;
                margin-bottom: 8px;
            }

            .forgot-password-link {
                font-size: 15px;
                margin-bottom: 12px;
                display: block;
                text-align: right;
                color: #42526e;
                cursor: pointer;

            }

            .forgot-password-link:hover {
                text-decoration: underline;
                color: #2d2de8;
            }

            form {
                .formInput {
                    margin-bottom: 14px;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                    }
                }

                .btn-submit {
                    width: 150px;
                    padding: 10px 20px;
                    display: block;
                    text-align: center;
                    border: none;
                    background-color: #0052cc;
                    color: white;
                    border-radius: 4px;
                    font-weight: bold;
                    cursor: pointer;
                    margin-top: 10px;
                    text-decoration: none;
                }
            }
        }

    }
}

.error-message {
    color: #96332c;
    font-size: 13px;
    margin: unset;
}

.verify-button-section {
    // width: 155px;
    padding: 10px 20px;
    text-align: center;
    border: none;
    background-color: #ffb300;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    height: 44px;
    margin-top: 10px;
    margin-left: 10px;
}

.badge-section {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #8c8a8a;
    word-break: break-all;
    // padding: 0.25em 0.4em;
    // white-space: nowrap;
    // color: #fff;
    // background-color: #007bff66;
}