form {
    .add-new-master-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .pickets-input{
            div{
                width: 100%;
                div{
                    width: 100%;
                    input{
                        box-sizing: unset;
                        border: unset;
                        min-width: 95%;
                    }
                }
            }
        }
        .formInput {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 49%;

            p{
                margin: unset;
            }

            label {
                display: inline-block;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            input {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            select {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            .select {
                min-width: 100%;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }

        .formInputDescription {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 100%;

            label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }


    }

    .btn-add-new {
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        background-color: #0052cc;
        font-weight: 400;
        border: 1px solid #0052cc;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        text-transform: capitalize;
        margin-left: 10px;

        svg {
            font-size: 16px;
        }
    }

}

.submit-modal {
    // width: 150px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #0052cc !important;
    color: white !important;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}

.close-modal {
    // width: 100px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #000 !important;
    color: white !important;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}

.submit-btn-disabled {
    // width: 150px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #706c6c !important;
    color: white !important;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}