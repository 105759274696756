.navbar {
    height: 60px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #172b4d;
    position: sticky;
    top: 0;
    background-color: #fff !important;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 999;

    .wrapper {
        width: 100%;
        // padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .toggle-navigation{
            display: flex;
            flex-direction: row;
            align-items: center;

            .btn-create-task {
                text-decoration: none;
                color: #ffffff;
                font-size: 14px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 6px 14px;
                border-radius: 4px;
                cursor: pointer;
                text-transform: capitalize;
                margin-left: 10px;
                display: flex;
                align-items: center;

                svg {
                    font-size: 20px;
                }
            }

            .icon {
                cursor: pointer;
            }
        }

        .search {
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;

            input {
                border: none;
                outline: none;
                background-color: transparent;

            }
        }

        .items {
            display: flex;
            align-items: center;

            .item {
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;

                .user-name-login {
                    font-size: 18px;
                    font-weight: 600;
                    color: #172b4d;
                }

                .icon {
                    font-size: 20px;
                }

                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .counter {
                    width: 15px;
                    height: 15px;
                    background-color: #00d748;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                }


            }


        }

    }


}

.MuiMenu-list-dropdown {
    .MuiPaper-elevation {
        ul {
            max-height: 300px;
            overflow-y: auto;

            li {
                display: block;
                border-bottom: 1px solid #cdccca;

                .notification-head {
                    display: block;
                    width: 100%;
                    font-size: 16px;
                }

                .notification-date {
                    display: block;
                    width: 100%;
                    font-size: 12px;
                }

                .see-all-notifiation {
                    background-color: #0052cc;
                    color: #fff;
                    display: block;
                    text-align: center;
                    padding: 5px 0px;
                    border-radius: 5px;
                    text-decoration: none;
                }
            }

            li:last-child {
                position: sticky;
                bottom: 0;
                border-bottom: unset;
            }
        }
    }

}